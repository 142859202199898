import React from 'react';
import { connect } from 'react-redux';

import StandardPage from '../components/StandardPage';
import TodoList from '../components/TodoList';
import Head from '../components/Head';

import SaveProgressModal from '../components/SaveProgressModal';
import PDFModal from '../components/PDFModal';
import TrelloModal from '../components/TrelloModal';
import '../style/_common.scss';

// import gql from 'graphql-tag';

class TodoPage extends React.Component {
  render() {
    return (
      <StandardPage hideAnnouncement={true}>
        <Head
          title="The DPC Startup Handbook"
          description="This 150-item checklist is the most thorough and up-to-date single resource for any aspiring direct primary care physician. It aggregates everything you need to know about DPC in one place."
          url="/todo/"
          image="/og_image.jpg"
        />
        {this.props.saveProgressModal && <SaveProgressModal />}
        {this.props.pdfModal && <PDFModal />}
        {this.props.trelloModal && <TrelloModal />}
        <div className="wide TodoPage">
          <TodoList />
        </div>
      </StandardPage>
    );
  }
}

const mstp = state => ({
  saveProgressModal: state.saveProgressModal,
  pdfModal: state.pdfModal,
  trelloModal: state.trelloModal
});

export default connect(mstp)(TodoPage);
